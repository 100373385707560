import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import './careers-hero.css';

const CareersHero: React.FC = () => {
    return (
        <section className="about-voices-section">
            <div className="about-voices-wrapper">
                <h1 className="text-3xl semi-bold">Amplify customer voices with us</h1>
                <p className="text-sm dark-gray">
                    We’re looking for unique individuals to help us channel customer service insights and automate manual processes. We are
                    working on difficult problems using distributed systems, machine learning and leading UX, so send us your resume if you
                    think you’re a good fit.
                </p>
            </div>
            <div className="about-voices-graphics-wrapper">
                <div className="container" style={{ height: '100%', position: 'relative' }}>
                    <div className="about-voices-graphic _1">
                        <StaticImage
                            src="../../assets/about/about-voices-1.png"
                            alt="about voices first"
                            layout="fixed"
                            width={432}
                            quality={100}
                            placeholder="none"
                        />
                        <div className="about-voices-shapes-wrapper _1">
                            <StaticImage
                                className="about-voice-shape _1-1"
                                src="../../assets/about/voices-1-1.png"
                                alt="about voices second"
                                layout="fixed"
                                width={76}
                                quality={100}
                                style={{ position: 'absolute' }}
                                placeholder="none"
                            />
                            <StaticImage
                                className="about-voice-shape _1-2"
                                src="../../assets/about/voices-1-2.png"
                                alt="about voices third"
                                layout="fixed"
                                width={90}
                                quality={100}
                                style={{ position: 'absolute' }}
                                placeholder="none"
                            />
                        </div>
                    </div>
                    <div className="about-voices-graphic _2">
                        <div className="about-voices-shapes-wrapper _2">
                            <StaticImage
                                className="about-voice-shape _2-1"
                                src="../../assets/about/voices-2-1.png"
                                alt="about voices"
                                layout="fixed"
                                width={76}
                                quality={100}
                                style={{ position: 'absolute' }}
                                placeholder="none"
                            />
                            <StaticImage
                                className="about-voice-shape _2-2"
                                src="../../assets/about/voices-2-2.png"
                                alt="shape"
                                layout="fixed"
                                width={90}
                                quality={100}
                                style={{ position: 'absolute' }}
                                placeholder="none"
                            />
                        </div>
                    </div>
                    <div className="about-voices-graphic _3">
                        <StaticImage
                            src="../../assets/about/about-voices-3.png"
                            alt="voices"
                            layout="fixed"
                            width={462}
                            quality={100}
                            placeholder="none"
                        />
                        <div className="about-voices-shapes-wrapper _3">
                            <StaticImage
                                className="about-voice-shape _3-1"
                                src="../../assets/about/voices-3-1.png"
                                alt="shape"
                                layout="fixed"
                                width={54}
                                quality={100}
                                style={{ position: 'absolute' }}
                                placeholder="none"
                            />
                            <StaticImage
                                className="about-voice-shape _3-2"
                                src="../../assets/about/voices-3-2.png"
                                alt="shape"
                                layout="fixed"
                                width={78}
                                quality={100}
                                style={{ position: 'absolute' }}
                                placeholder="none"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CareersHero;
