import React from 'react';
import { graphql } from 'gatsby';

import HtmlHead from '../components/html-head';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import CareersHero from '../components/careers-hero';
import { JobOpeningData } from '../types';
import CareersPageOpenings from '../components/careers-page-openings';

import '../styles/index.css';

type CareersPageProps = {
    data: {
        allContentfulPageSeoData: {
            edges: {
                node: {
                    title: string;
                    canonicalLink: string;
                    metaDescription: {
                        metaDescription: string;
                        noindex: string;
                    };
                    openGraphImage: {
                        file: {
                            url: string;
                        };
                    };
                };
            }[];
        };
        allContentfulJobOpening: {
            edges: {
                node: JobOpeningData;
            }[];
        };
    };
};

const Careers: React.FC<CareersPageProps> = ({data}) => {
    const seoData = data.allContentfulPageSeoData.edges[0].node;

    return (
        <>
            <HtmlHead
                title={seoData.title}
                metaDescription={seoData.metaDescription.metaDescription}
                canonicalLink={seoData.canonicalLink}
                openGraphImage={seoData.openGraphImage.file.url}
                noindex= {true}
            />
            <Navbar />
            <CareersHero />
            <CareersPageOpenings openings={data.allContentfulJobOpening.edges}/>
            <Footer />
        </>
    );
};

export default Careers;

export const query = graphql`
    query CareersQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/careers" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
        allContentfulJobOpening {
            edges {
                node {
                    jobTitle
                    jobField
                    createdAt(formatString: "MMM[.] DD[,] YYYY")
                    description {
                        raw
                    }
                    applicationLink
                    location
                }
            }
        }
    }
`;