import React from 'react';
import { JobOpeningData } from '../../types';
import { JobOpeningTile } from '../job-opening-tile';

import './careers-page-openings.css';

type CareersPageOpenings = {
    openings: {
        node: JobOpeningData;
    }[];
};

const CareersPageOpenings: React.FC<CareersPageOpenings> = () => {
    return (
        <section className="careers-body-section">
            <div className="container">
                <div className="careers-body-wrapper">
                    <h3 className="bold">Open Positions</h3>
                    <div className="email-link-wrapper">
                        <a className="email-link" href="mailto:jobs@aptedge.io">Contact Us By Email</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CareersPageOpenings;
